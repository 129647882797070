import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export const FooterWrapper = styled.div`
  background-color: #222;
  padding-top: 80px;
  padding-bottom: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const FooterInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

export const FooterColumn = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`;

export const CopyrightWrapper = styled.div`
  background-color: rgb(235, 235, 235);
  padding: 40px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    font-size: 13px;
    margin-bottom: 5px;
    @media only screen and (max-width: 991px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const CompanyDescriptionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .merchantName {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    & > h1 {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      text-align: left;
      margin-bottom: 0.5rem;
      line-height: 1.2;
    }
  }

  .merchantDesc {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    & > p {
      color: #ccc;
      text-align: left;
      font-size: 13px;
      margin-bottom: 4px;
    }
  }

  .space {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  .helpfulLinkCol {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: left;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      margin-right: -15px;
      margin-left: -15px;
      margin-bottom: -9px;

      a {
        color: rgb(204, 204, 204);
        font-size: 13px;
        font-weight: 400;
        text-decoration: none;
        padding: 0.375rem 0.75rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .NewsCol {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    a {
      color: rgb(204, 204, 204);
      font-size: 13px;
      text-decoration: none;
      margin-left: 1px;
      font-weight: 400;
      padding: 0.375rem 0.75rem;
    }
  }

  .NewsSpace {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .email {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const News = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 5px;
    font-size: 16px;
  }
  .news-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      p {
        margin-bottom: 0;
        color: ${themeGet("primary.0", "#ff0000")};
        font-size: 13px;
      }
      a {
        font-weight: 700;
        color: #7a7a7a;
        transition: 500ms;
        font-size: 13px;

        &:hover {
          color: ${themeGet("primary.0", "#ff0000")};
          transition: 500ms;
        }
      }
    }
  }
`;

export const Col = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  .poweredBy {
    font-weight: bold;
    color: rgb(255, 255, 255);
    font-size: 12px;
    margin-bottom: 0.5rem;
    line-height: 1.2;
  }

  .poweredByLogo {
    padding: 0.375rem 0.75rem;
    display: inline-block;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;

    img {
      width: 100px;
      margin-left: -14px;
      vertical-align: middle;
      border-style: none;
      max-width: fit-content;
    }
  }
`;

export const Footertitle = styled.h1`
  margin-bottom: 0.5rem;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  line-height: 1.2;
`;

export const FooterText = styled.p`
  color: #ccc;
  text-align: left;
  font-size: 13px;
`;

export const ProwerByRow = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
`;

export const CopyRightRow = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  h1 {
    color: #ccc;
    text-align: left;
    font-size: 13px;
  }
`;

export const VersionRow = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  h1 {
    color: #ccc;
    text-align: left;
    font-size: 13px;
  }
`;

export const FooterDivier = styled.div`
  margin-top: 20px;
  margin-bottom: 25px;
  & > div {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;

    & > div {
      height: 1px;
      background-color: rgb(255, 255, 255);
    }
  }
`;

export const FooterBottom = styled.div`
  margin-top: 20px;
  margin-bottom: 35px;
`;
