import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { Button, Radio } from "antd";
import defaultTheme from "../../themes/default.theme";

export const FilterButtonItem = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;

  > div {
    @media only screen and (max-width: 820px) {
      font-size: 13px;
    }
  }
`;

export const BasicButton = styled.button`
  margin-right: 8px;
  padding: 5px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
`;

export const RadioGroup = styled(Radio.Group)`
  & .ant-radio-button-wrapper {
    margin: 5px;
    padding: 20px 5px;
    width: 100px;
    height: 100px;
  }

  & .ant-radio-button-wrapper:hover {
    background: ${defaultTheme.button.red} !important;
    border-color: ${defaultTheme.button.red} !important;
  }

  & .ant-radio-button.ant-radio-button-checked,
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    )::before {
    background: ${defaultTheme.button.red} !important;
    border-color: ${defaultTheme.button.red} !important;
  }
`;

export const RadioButton = styled(Radio.Button)`
  background: ${({ isSelected }) =>
    isSelected ? defaultTheme.button.red : "#fff"};
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};

  &:hover {
    color: ${({ isSelected }) => (isSelected ? "#fff" : "#000")};
  }

  & img {
    margin-bottom: 9px;
  }

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1rem;
    text-align: center;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  background-color: #ffffff;
  border-radius: 33px;
  //margin-top: 100px;
  width: 100%;

  @media (max-width: 992px) {
    margin-top: 0px;
  }

  & > form {
    width: 100%;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  //justify-content: space-between;
  background-color: ${({ isListPage }) => isListPage && "#F7F7F7"};
  border-radius: 20px;
  padding: ${({ isListPage }) => isListPage && "10px 10px 10px 10px"};

  @media (max-width: 992px) {
    justify-content: ${({ isListPage }) => isListPage && "center"};
  }

  .isListPageSerachButton {
    border-radius: 20px;
    height: 40px;
    @media (max-width: 992px) {
      width: 200px;
      margin-top: 0.5em;
    }
    div {
      width: 100%;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: ${({ width }) => width || "100%"};

  .css-tj5bde-Svg {
    height: 12px;
    width: 12px;
  }

  .target {
    right: 10px;
    left: auto;

    @media only screen and (max-width: 480px) {
      right: 25px;
    }
  }

  .css-g1d714-ValueContainer {
    padding-left: 0px;
    padding-right: 0px;
  }

  .css-qc7rj8-indicatorContainer {
    padding: 0;
  }
  .css-1qjkg08-indicatorContainer {
    padding: 0;
  }

  .bookingType-selector {
    .css-g1d714-ValueContainer {
      justify-content: space-between;
    }
    .ant-select-dropdown {
      z-index: 10005 !important;
    }

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
    }

    width: 100%;

    div {
      margin-bottom: 0px !important;
    }
  }

  .booking-type-icon {
    margin-right: 10px;
  }

  .location-selector {
    width: 100%;

    .ant-select-selector {
      background-color: transparent !important;
      border: none !important;
      width: 100%;
    }

    div {
      margin-bottom: 0px !important;
    }
  }

  /* date picker style */

  .ant-picker {
    padding: 0;
    border: none !important;
    background: none;
    width: 100%;
  }

  .ant-picker-input input {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding-right: 5px !important;
    text-overflow: initial !important;

    @media (max-width: 992px) {
      text-align: ${({ isListPage }) => isListPage && "center"};
    }
  }

  .ant-calendar-picker-input.ant-input {
    color: red !important;
  }

  .ant-picker.ant-picker-range {
    background: none !important;
    border: none !important;
  }

  .ant-picker-focused {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: red !important;
  }

  .date_picker {
    width: 100%;
    margin: 0 0 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 991px) {
      justify-content: flex-start;
      height: 60px;
    }

    @media only screen and (max-width: 480px) {
      height: 47px;
    }

    .DayPicker__withBorder {
      box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    }

    .DateRangePicker {
      display: block;
      width: 100%;

      .DateRangePickerInput {
        border: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;

        .DateInput {
          width: 100%;
          padding: 0 15px 0 30px;
          background: transparent;

          @media only screen and (min-width: 569px) and (max-width: 991px) {
            padding: 0 25px 0 30px;
          }

          @media only screen and (max-width: 568px) {
            padding: 0 15px 0 25px;
          }

          .DateInput_fang {
            display: none;
          }

          .DateInput_input {
            padding: 0;
            font-weight: 400;
            color: ${themeGet("text.0", "#2C2C2C")};
            background: transparent;

            &.DateInput_input__focused {
              border-color: transparent;
            }

            &::placeholder {
              color: ${themeGet("text.0", "#2C2C2C")};
            }
          }
        }

        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
      }

      .DateRangePicker_picker {
        margin-top: -12px;
        z-index: 2;

        @media only screen and (max-width: 991px) {
          top: 47px !important;
        }

        @media only screen and (max-width: 320px) {
          left: -29px !important;
          .DayPicker,
          .DayPicker > div > div,
          .DayPicker > div > div .DayPicker_transitionContainer {
            width: 294px !important;
          }

          .DayPicker {
            .DayPicker_weekHeader {
              padding: 0 !important;
            }

            .CalendarMonth {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  /* icon style */

  > svg {
    position: absolute;
    z-index: 1;
    top: auto;

    &.map-marker,
    &.calendar {
      left: 10px;
      fill: ${themeGet("primary.0", "#008489")};
    }

    &.calendar {
      @media only screen and (max-width: 480px) {
        width: 14px;
        height: 14px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;
      fill: ${themeGet("text.1", "#909090")};

      @media only screen and (max-width: 991px) {
        right: 20px;
      }

      @media only screen and (max-width: 991px) {
        right: 15px;
      }
    }
  }
`;

export const MonthlyCol = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: ${({ isListPage }) => !isListPage && "0 0 16.666667%"};
    max-width: ${({ isListPage }) => !isListPage && "16.666667%"};
    width: ${({ isListPage }) => isListPage && "150px"};
  }
`;

export const FromToCol = styled.div`
  position: relative;
  width: ${({ isListPage }) => (isListPage ? "50%" : "100%")};
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 10px;

  @media (min-width: 992px) {
    flex: ${({ isListPage }) => !isListPage && "0 0 25%"};
    max-width: ${({ isListPage }) => !isListPage && "25%"};
    margin-top: 0px;
    width: ${({ isListPage }) => isListPage && "160px"};
  }
`;

export const ParkingAtCol = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  @media (min-width: 992px) {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
`;

export const SearchButtonCol = styled.div`
  position: relative;
  width: 100%;
  height: 66px;
  padding: 12px 15px;

  @media (min-width: 992px) {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .home-page-search-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    width: 100%;
    border-radius: 20px;
    height: auto;

    &:hover {
      color: #fff;
    }

    & > div {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
